<template>
  <div v-if="ready">
    <div class="card card-custom gutter-b mt-3" style="overflow: inherit;">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{$store.back.getText('acompte')}}</h3>
        </div>
        <div class="card-toolbar">
          <b-button v-if="type != 'acompte_compte_fournisseur'" variant="primary" size="sm" @click="addAcompte()" class="btn-icon mr-1"><i class="icon-md fas fa-plus"></i></b-button>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-striped table-mobile">
          <thead>
            <tr>
              <th scope="col" v-if="type === 'acompte_compte_fournisseur'">{{$store.back.getText("prestataire")}}</th>
              <th scope="col">{{$store.back.getText("mode_paiement")}}</th>
              <th scope="col">{{$store.back.getText("reference")}}</th>
              <th scope="col">{{$store.back.getText("montant")}}</th>
              <th scope="col">{{$store.back.getText("date")}}</th>
              <th scope="col" v-if="type === 'acompte_compte_client'">Num. Facture</th>
              <th scope="col" v-if="type === 'acompte_compte_fournisseur'">Facture</th>
              <th scope="col">{{$store.back.getText("action")}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(a,i) in obj[type]" :key="i">
              <td scope="row" v-if="type === 'acompte_compte_fournisseur'">
                <select class="form-control" v-model.number="a.prestataire_id">
                  <option v-for="(f,cf) in compteFournisseur" :key="cf" :value="f.prestataire_id">{{params.Prestataire.find(p => p.id == f.prestataire_id)?.societe ?? 'TOTO'}}</option>
                </select>
              </td>
              <td scope="row">
                <select class="form-control" v-model.number="a.acompte_type">
                  <option v-for="(t,it) in params.AcompteType" :key="it" :value="t.id">{{t.titre.fr}}</option>
                </select>
              </td>
              <td scope="row">
                <b-form-input v-model="a.commentaire" required></b-form-input>
              </td>
              <td scope="row">
                <b-form-input v-model.number="a.montant" required></b-form-input>
              </td>
              <td scope="row">
                <input type="date" class="form-control" v-model="a.date" style="display:overflow" />
              </td>
              <td scope="row" v-if="type === 'acompte_compte_client'">
                <b-form-input v-model="a.facture_ref" required></b-form-input>
              </td>
              <td scope="row" v-if="type === 'acompte_compte_fournisseur'">
                <b-form-checkbox size="lg" class="mt-2" v-model="a.facture_recu">Reçu</b-form-checkbox>
              </td>
              <td scope="row">
                <b-button-group size="sm">
                  <b-button variant="success" class="btn-icon" @click="addDoc(a.uuid)"><i class="icon-md fas fa-file"></i></b-button>
                  <b-button class="btn-icon" @click="openDoc(a.uuid)" :variant="docRowEmpty(a.uuid) ? 'warning' : 'primary'" ><i class="icon-md fas fa-eye"></i></b-button>
                  <b-button variant="danger" class="btn-icon"  @click="openConfirmationRemove(i)"><i class="icon-md fas fa-minus"></i></b-button>
                </b-button-group>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-modal :id="type+'remove_acompte'" title="Confirmation" :ref="type+'remove_acompte'">
      <p>Vous êtes sur le point de supprimer un acompte !</p>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="danger" class="float-right" @click="removeAcompte()">
            Confirmer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
export default {
  name: "Adresse",
  model: {
    prop: 'obj',
  },
  props: {
    obj: Object,
    type: String,
    params: Object
  },
  data(){
    return {
      ready:false,
      acompteIndex: null
    }
  },
  computed: {
    compteFournisseur(){
      var check = this.parent.compteFournisseur;
      if(check === undefined){
        return []
      }
      return check;
    },
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  methods: {
    addAcompte(prestataire_id = null){      
      var tmp = {
        "prestataire_id": prestataire_id,
        "uuid": uuidv4(),
        "acompte_type": null,
        "montant": 0,
        "date": null,
        "commentaire":null
      }
      switch (this.type) {
        case 'acompte_compte_client':
          tmp.facture_ref = null;
        break;
        case 'acompte_compte_fournisseur':
          tmp.facture_recu = false;
        break;
      }      
      tmp = JSON.parse(JSON.stringify(tmp))
      this.obj[this.type].push(tmp);
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })

    },
    removeAcompte(){
      if(this.acompteIndex === null){
        return false
      }
      this.obj[this.type].splice(this.acompteIndex,1);
      this.acompteIndex = null;
      this.$refs[this.type+'remove_acompte'].hide();
    },
    openConfirmationRemove(i){
      this.acompteIndex = i;
      this.$refs[this.type+'remove_acompte'].show();
    },
    addDoc(uuid){
      this.parent.tmpDoc.prestataire_id = uuid;
      this.parent.$refs.import.show();
    },
    openDoc(uuid){
      this.parent.doc_prestataire_id = uuid;
      this.parent.toggleFiles = true;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    docRowEmpty(uuid){
      var tmp = this.obj.docs.filter(d => d.prestataire_id === uuid);
      return tmp.length === 0 ? true : false;
    },
  },
  beforeMount() {
  },
  mounted() {
    this.ready = true;
  }
};
</script>
