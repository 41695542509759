<template>
  <div v-if="ready"> 
    <div class="card card-custom card-nobody gutter-b sticky-top">
      <div class="card-header">
        <div class="card-title div-center">
          <h3 class="card-label">Fiche propriete #{{propriete.id}}</h3>
        </div>
        <div class="card-title div-center">
          <b-button block :variant="proprieteStatus.color" size="lg" style="font-size:1.20em">{{proprieteStatus.titre.fr}}</b-button>
        </div>
        <div class="card-toolbar div-center">
          <b-button v-b-modal.deleteProp variant="danger" size="sm" class="btn-icon mr-1"><i class="icon-md fas fa-trash"></i></b-button>
          <b-dropdown class="mr-1" size="sm" variant="dark" html='<i class="fas fa-thermometer-three-quarters"></i>'>
            <b-dropdown-item v-for="l in params.ProprieteStatus" :key="l.id" @click="changeStatus(l.id)" :active="l.id === propriete.status_id">{{l.titre.fr}}</b-dropdown-item>
          </b-dropdown>
          <b-button variant="info" size="sm" class="btn-icon mr-1" v-b-modal.calendar><i class="icon-md fas fa-calendar"></i></b-button>
          <b-button variant="primary" size="sm" @click="toggleFiles = !toggleFiles" class="btn-icon mr-1"><i class="icon-md fas fa-file"></i></b-button>
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="save()" v-if="$store.getters.currentUser.role === 1">
            <i v-if="loader_save === false" class="icon-md fas fa-save"></i>
            <i v-else class="fas fa-circle-notch fa-spin"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b mt-3" :class="toggleFiles ? '' : 'd-none'">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{$store.back.getText('liste-fichier')}}</h3>
        </div>
        <div class="card-toolbar">
          <b-button v-if="doc_prestataire_id != null" variant="danger" size="sm" class="btn-icon mr-1" @click="doc_prestataire_id = null"><i class="icon-md fas fa-minus"></i></b-button>
          <b-button variant="primary" size="sm" class="btn-icon mr-1" v-b-modal.import><i class="icon-md fas fa-plus"></i></b-button>
        </div>
      </div>
      <div class="card-body">
        <div>
          <b-row>
            <b-button size="md" variant="success" @click="docs_filter = null" class="ml-5 mr-1">Tout</b-button>
            <b-button size="md" variant="primary" class="mr-1" v-for="(t,i) in params.GEDType"  @click="docs_filter = t.id"  :key="i">{{t.titre.fr}}</b-button>
          </b-row>
          <table class="table table-hover mt-2">
            <thead>
              <tr>
                <th scope="col">{{$store.back.getText('titre')}}</th>
                <th scope="col">{{$store.back.getText('type')}}</th>
                <th scope="col">{{$store.back.getText('date_create')}}</th>
                <th scope="col">{{$store.back.getText('action')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d,i) in docsFiltered" :key="i">
                <td>
                  {{getFileName(d)}}
                </td>
                <td>
                  {{params.GEDType.find(t => t.id === d.ged_type).titre.fr}}
                </td>
                <td>
                  {{$store.back.timestampToDate(d.date_create,false)}}
                </td>
                <td>
                  <b-button-group>
                    <b-button style="padding:0.3rem" size="sm" variant="primary" :href="d.url" target="_blank"><i class="fas fa-eye"></i></b-button>
                    <b-button style="padding:0.3rem" size="sm" variant="danger" @click="removeFile(d.id)"><i class="fas fa-minus"></i></b-button>
                  </b-button-group>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-tabs pills card>
      <b-tab :title="$store.back.getText('generale')" active class="tab-card-body">
        <div class="card-deck">
          <div class="card card-custom gutter-b" v-if="projet.id != 0">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">{{$store.back.getText('info-projet')}}</h3>
              </div>
            </div>
            <div class="card-body">
              <b-row>
                <b-col md="6">
                  <b-form-group :label="this.$store.back.getText('titre')+':'">
                    <b-form-input v-model="projet.titre" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('constructeur')+':'">
                    <b-form-input v-model="projet.constructeur" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group :label="this.$store.back.getText('date-livraison')+':'">
                    <b-row>
                      <b-col md="6">
                        <select class="form-control" v-model="projet.date_livraison.mois" disabled>
                          <option :value="null">Mois</option>
                          <option value="01">Janvier</option>
                          <option value="02">Fevrier</option>
                          <option value="03">Mars</option>
                          <option value="04">Avril</option>
                          <option value="05">Mai</option>
                          <option value="06">Juin</option>
                          <option value="07">Juillet</option>
                          <option value="08">Aout</option>
                          <option value="09">Septembre</option>
                          <option value="10">Octobre</option>
                          <option value="11">Novembre</option>
                          <option value="12">Decembre</option>
                        </select>
                      </b-col>
                      <b-col md="6">
                        <select class="form-control" v-model="projet.date_livraison.annee" disabled>
                          <option :value="null">Année</option>
                          <option value="2021">2021</option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                          <option value="2026">2026</option>
                          <option value="2027">2027</option>
                          <option value="2028">2028</option>
                          <option value="2029">2029</option>
                          <option value="2030">2030</option>
                          <option value="2031">2031</option>
                          <option value="2032">2032</option>
                          <option value="2033">2033</option>
                          <option value="2034">2034</option>
                          <option value="2035">2035</option>
                        </select>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-button variant="warning" size="sm" class="btn-icon mr-1 mt-9" :href="'/#/projet/detail/'+projet.id"><i class="icon-md fas fa-eye"></i></b-button>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="card card-custom gutter-b">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">{{$store.back.getText('info-client')}}</h3>
              </div>
            </div>
            <div class="card-body">
              <b-row>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('last_name')+':'">
                    <b-form-input v-model="client.nom" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('first_name')+':'">
                    <b-form-input v-model="client.prenom" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('phone')+':'">
                    <b-form-input v-model="client.telephone" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('email')+':'">
                    <b-form-input v-model="client.email" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-button variant="warning" size="sm" class="btn-icon mr-1 mt-9" :href="'/#/client/detail/'+client.id"><i class="icon-md fas fa-eye"></i></b-button>
                </b-col>
              </b-row>
            </div>
          </div>          
        </div>
        <b-card-group class="mt-3" deck>
          <div class="card card-custom gutter-b">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">{{$store.back.getText('detail-propriete')}}</h3>
              </div>
            </div>
            <div class="card-body">
              <b-row>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('type')+':'">
                    <select class="form-control" v-model="propriete.type_id">
                      <option v-for="(t,i) in params.ProjetType" :value="t.id" :key="i">{{t.titre.fr}}</option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('superficie')+':'">
                    <b-form-input v-model.number="propriete.superficie" type="number"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('etage')+':'">
                    <b-form-input v-model="propriete.etage"></b-form-input>
                  </b-form-group>
                </b-col>              
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('numero_propriete')+':'">
                    <b-form-input v-model="propriete.numero_propriete"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group :label="this.$store.back.getText('parking')+':'">
                    <b-form-input v-model.number="propriete.parking" type="number"></b-form-input>
                  </b-form-group>
                </b-col>              
              </b-row>
            </div>
          </div>
          <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">{{$store.back.getText('adresse')}}</h3>
                </div>
              </div>
              <div class="card-body">
                <b-row>
                  <b-col>
                    <b-form-group :label="this.$store.back.getText('google-autocomplete')+':'">
                      <vue-google-autocomplete id="map" classname="form-control" placeholder="Adresse"  v-on:placechanged="getAddressData" country="il"> </vue-google-autocomplete>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="this.$store.back.getText('adresse')+':'">
                      <b-form-input v-model="propriete.adresse.route" type="text" required disabled></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group :label="this.$store.back.getText('numero')+':'">
                      <b-form-input v-model.number="propriete.adresse.street_number" type="number" required disabled></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="this.$store.back.getText('ville')+':'">
                      <b-form-input v-model="propriete.adresse.locality" type="text" required disabled></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="this.$store.back.getText('pays')+':'">
                      <b-form-input v-model="propriete.adresse.country" type="text" required disabled></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="this.$store.back.getText('quartier')+':'">
                      <select class="form-control" v-model.number="propriete.adresse.quartier_id">
                        <option v-for="(q,i) in params.Quartier" :key="i" :value="q.id">{{q.titre.fr}}</option>
                      </select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
          </div>
        </b-card-group>        
      </b-tab>      
      <b-tab :title="$store.back.getText('compte_client')" class="tab-card-body">
        <div class="card card-custom gutter-b mt-3">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$store.back.getText('detail-financier')}}</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="danger" size="sm" @click="recapPDF()" class="btn-icon mr-1"><i class="icon-md fas fa-file-pdf"></i></b-button>
              <b-button variant="primary" size="sm" @click="addCompteClient()" class="btn-icon mr-1"><i class="icon-md fas fa-plus"></i></b-button>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped table-mobile">
              <thead>
                <tr>
                  <th scope="col">{{$store.back.getText("type")}}</th>
                  <th scope="col">{{$store.back.getText("prestataire")}}</th>
                  <th scope="col">{{$store.back.getText("objet")}}</th>
                  <th scope="col">TVA %</th>
                  <th scope="col">{{$store.back.getText("prix_achat")}}</th>
                  <th scope="col">{{$store.back.getText("prix_vente")}}</th>
                  <th scope="col">{{$store.back.getText("action")}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(q,i) in propriete.compte_client" :key="i">
                  <td scope="row">
                    <select class="form-control" v-model.number="q.prestataire_type">
                      <option v-for="(t,it) in params.PrestataireType" :key="it" :value="t.id">{{t.titre.fr}}</option>
                    </select>
                  </td>
                  <td scope="row">
                    <select class="form-control" v-model.number="q.prestataire_id">
                      <option :value="null">{{$store.back.getText("aucun")}}</option>
                      <option v-for="(p,ip) in params.Prestataire.filter(p => p.type_id == q.prestataire_type)" :key="ip" :value="p.id">{{p.societe}}</option>
                    </select>
                  </td>
                  <td scope="row">
                    <b-form-input v-model="q.objet" required></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-input v-model="q.tva" required></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-input v-model.number="q.prix_achat" required></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-input v-model.number="q.prix_vente" required></b-form-input>
                  </td>
                  <td scope="row">
                    <b-button-group size="sm">
                      <b-button variant="success" class="btn-icon" @click="addDocRow(q.uuid)"><i class="icon-md fas fa-file"></i></b-button>
                      <b-button :variant="docRowEmpty(q.uuid) ? 'warning' : 'primary'" class="btn-icon" @click="openDocRow(q.uuid)"><i class="icon-md fas fa-eye"></i></b-button>
                      <b-button variant="danger" class="btn-icon"  @click="openConfirmation('compte_client',q.uuid)"><i class="icon-md fas fa-minus"></i></b-button>
                    </b-button-group>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-row>
              <b-col md="4">
                <b-list-group>
                  <b-list-group-item active style="text-align: center;">Achat</b-list-group-item>
                  <b-list-group-item>
                    <span>HT</span><span style="font-weight: bold;" class="ml-2">{{formatedNumber(totalAchat)}}</span>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>TVA</span><span style="font-weight: bold;" class="ml-2">{{formatedNumber(totalAchatTVA)}}</span>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>TTC</span><span style="font-weight: bold;" class="ml-2">{{formatedNumber(totalAchat+totalAchatTVA)}}</span>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col md="4">
                <b-list-group>
                  <b-list-group-item active style="text-align: center;">Vente</b-list-group-item>
                  <b-list-group-item>
                    <span>HT</span><span style="font-weight: bold;" class="ml-2">{{formatedNumber(totalVente)}}</span>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>TVA</span><span style="font-weight: bold;" class="ml-2">{{formatedNumber(totalVenteTVA)}}</span>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>TTC</span><span style="font-weight: bold;" class="ml-2">{{formatedNumber(totalVente+totalVenteTVA)}}</span>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col md="4">
                <b-list-group>
                  <b-list-group-item>
                    <span>{{$store.back.getText('acompte-recu')}}</span><span style="font-weight: bold;" class="ml-2">{{formatedNumber(totalAcompte)}}</span>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>{{$store.back.getText('solde')}}</span><span style="font-weight: bold;" class="ml-2">{{formatedNumber((totalVente+totalVenteTVA) - totalAcompte)}}</span>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>{{$store.back.getText('benefice_client')}}</span><span style="font-weight: bold;" class="ml-2">{{formatedNumber(totalVente - totalAchat)}}</span>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>{{$store.back.getText('benefice_fournisseur')}}</span><span style="font-weight: bold;" class="ml-2">{{formatedNumber(beneficeFournisseur)}}</span>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
          </div>
        </div>
        <Acompte v-model="propriete" :params="params" type="acompte_compte_client" /> 
      </b-tab>
      <b-tab :title="$store.back.getText('compte_fournisseur')" class="tab-card-body">
        <div class="card card-custom gutter-b mt-3">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$store.back.getText('detail-financier')}}</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="primary" size="sm" v-b-modal.add_compte_fournisseur class="btn-icon mr-1"><i class="icon-md fas fa-plus"></i></b-button>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-mobile">
              <thead>
                <tr>
                  <th scope="col" style="width: 12%">{{$store.back.getText("type")}}</th>
                  <th scope="col" style="width: 24%">{{$store.back.getText("prestataire")}}</th>
                  <th scope="col" style="width: 16%">{{$store.back.getText("objet")}}</th>
                  <th scope="col" style="width: 14%">{{$store.back.getText("prix_achat")}}</th>
                  <th scope="col" style="width: 14%">{{$store.back.getText("acompte")}}</th>
                  <th scope="col" style="width: 14%">{{$store.back.getText("solde")}}</th>
                </tr>
              </thead>
              <tbody v-for="(q,i) in compteFournisseur" :key="i">
                <tr>
                  <td scope="row">
                    <select class="form-control" v-model.number="q.prestataire_type" disabled>
                      <option v-for="(t,it) in params.PrestataireType" :key="it" :value="t.id">{{t.titre.fr}}</option>
                    </select>
                  </td>
                  <td scope="row">
                    <select class="form-control" v-model.number="q.prestataire_id" disabled>
                      <option :value="null">{{$store.back.getText("aucun")}}</option>
                      <option v-for="(p,ip) in params.Prestataire.filter(p => p.type_id == q.prestataire_type)" :key="ip" :value="p.id">{{p.societe}}</option>
                    </select>
                  </td>
                  <td scope="row">
                    <b-form-input v-model="q.objet"></b-form-input>
                  </td>
                  <td scope="row">
                    <b-input-group>
                      <template #append>
                        <b-button variant="primary" size="sm" v-b-toggle="'toggle-'+i" class="btn-icon" :id="'ac-four-view'+i"><i class="fas fa-eye"></i></b-button>
                        <b-button variant="danger" size="sm" class="btn-icon" @click="recapFournisseur(q.prestataire_id)"><i class="fas fa-file-pdf"></i></b-button>
                      </template>
                      <b-form-input :value="formatedNumber(q.total_achat)" disabled></b-form-input>
                    </b-input-group>
                  </td>
                  <td scope="row">
                    <b-input-group>
                      <template #append>
                        <b-button variant="success" class="btn-icon" :id="'ac-four'+i"><i class="fas fa-plus" @click="addAcompteCompteFournisseur(q.prestataire_id)"></i></b-button>
                      </template>
                      <b-form-input  :value="formatedNumber(q.total_acompte)" disabled></b-form-input>
                    </b-input-group>
                  </td>
                  <td scope="row">
                    <b-form-input :value="formatedNumber((q.total_achat + q.total_tva) - q.total_acompte)" type="text" disabled></b-form-input>
                  </td>
                </tr>
                <b-collapse :id="'toggle-'+i">
                  <tr v-for="(d,di) in q.detail" :key="di">
                    <td></td>
                    <td>
                      <b-button-group>
                        <b-button>Doc</b-button>
                        <b-button variant="success" class="btn-icon" @click="addDocRow(d.uuid)"><i class="icon-md fas fa-file"></i></b-button>
                        <b-button :variant="docRowEmpty(d.uuid) ? 'warning' : 'primary'" class="btn-icon" @click="openDocRow(d.uuid)"><i class="icon-md fas fa-eye"></i></b-button>
                      </b-button-group>
                    </td>
                    <td><b-form-input v-model="d.objet" type="text"></b-form-input></td>
                    <td>
                      <b-input-group>
                        <template #append>
                          <b-button variant="danger" class="btn-icon" @click="openConfirmation('compte_fournisseur',d.uuid)"><i class="fas fa-trash"></i></b-button>
                        </template>
                        <b-form-input v-model.number="d.prix_achat" type="text"></b-form-input>
                      </b-input-group>
                    </td>
                    <td>
                      <b-input-group prepend="TVA %">
                        <b-form-input v-model.number="d.tva"></b-form-input>
                      </b-input-group>
                    </td>
                    <td></td>
                  </tr>
                </b-collapse>
              </tbody>
              <tfoot>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col" style="text-align: center;">{{$store.back.getText("total")}}</th>
                  <th scope="col" style="text-align: center;">{{formatedNumber(totalFournisseurTTC)}} (TTC)</th>
                  <th scope="col" style="text-align: center;">{{formatedNumber(totalAcompteFournisseur)}}</th>
                  <th scope="col" style="text-align: center;">{{formatedNumber(totalFournisseurTTC - totalAcompteFournisseur)}}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <Acompte v-model="propriete" :params="params" type="acompte_compte_fournisseur" />
      </b-tab>                  
      <b-tab title="Taches" class="tab-card-body">
        <div class="card card-custom gutter-b card-nobody">          
          <div class="card-body">
            <b-table-simple hover responsive>        
              <b-thead>                
                <b-tr>
                  <b-th>ID</b-th>
                  <b-th>Type</b-th>
                  <b-th>Format</b-th>
                  <b-th>Date</b-th>
                  <b-th>Action</b-th>                  
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(t,i) in taches" :key="i">                  
                  <b-td>{{ t.id }}</b-td>
                  <b-td>{{ t.type }}</b-td>
                  <b-td>{{ t.ext }}</b-td>
                  <b-td>{{ $store.back.timestampToDate(t.date_create) }}</b-td>
                  <b-td>
                    <b-button-group size="sm">
                      <b-button size="sm" class="btn-icon" variant="danger" @click="deleteTache(t.id)"><i class="fas fa-trash"></i></b-button>
                      <b-button size="sm" class="btn-icon" variant="light-info" @click="openTache(t)"><i class="fas fa-eye"></i></b-button>
                    </b-button-group>
                  </b-td>                  
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div class="card-deck mt-3">
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{$store.back.getText('commentaire')}}</h3>
          </div>
        </div>
        <div class="card-body">
          <b-form-group :label="this.$store.back.getText('commentaire')+':'">
            <b-form-textarea v-model="propriete.commentaire" rows="4" readonly></b-form-textarea>
          </b-form-group>
          <b-form-group :label="this.$store.back.getText('ajouter')+':'">
            <b-input-group>
              <b-form-input v-model="commentaireTmp" type="text" required placeholder="Entre votre commentaire"></b-form-input>
              <template #append>
                <b-button variant="success" class="btn-icon mr-1" @click="addCommentaire()"><i class="icon-md fas fa-plus"></i></b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </div>
      </div>
    </div>
    <b-modal id="deleteProp" title="Supprimer" size="sm" ref="modalStatus">
      <b-card-text>Etes-vous sur de vouloir supprimer la propriete ?</b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="danger" class="float-right" @click="deletePropriete()">
            Confirmer
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="search" title="Confirmation du statut" size="sm" ref="modalStatus">
      <div class="row">
        <div class="form-group col-md-12">
          <label>Statut</label>
          <select class="form-control" v-model="propriete.status_id">
            <option v-for="s in params.ProprieteStatus" :value="s.id" :key="s.id">{{s.titre.fr}}</option>
          </select>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="save(true)">
            Valide
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="import" title="Importation de document" ref="import">
      <b-form>
        <b-form-group label="Type:">
          <select class="form-control" v-model.number="tmpDoc.ged_type">
            <option v-for="(t,i) in params.GEDType" :key="i" :value="t.id">{{t.titre.fr}}</option>
          </select>
        </b-form-group>
        <b-form-group label="Dociment:">
          <b-form-file  @change="handleFile" placeholder="Selectionnez votre fichier a importer"></b-form-file>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button v-if="loaderImportFile === false" variant="primary" class="float-right" @click="addDoc()">
            Valide
          </b-button>
          <b-button v-else variant="info" size="sm" class="btn-icon mr-1 float-right"> <i class="fas fa-circle-notch fa-spin"></i></b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="calendar" title="Calendrier" ref="calendar">
      <b-form>
        <b-form-group label="Titre:">
          <b-form-input v-model="calendarTmp.name" type="text" required placeholder="Entre le titre"></b-form-input>
        </b-form-group>
        <b-form-group label="Detail:">
          <b-form-input v-model="calendarTmp.detail" type="text" required placeholder="Entre le deail"></b-form-input>
        </b-form-group>
        <b-form-group label="Debut:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart"></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeStart"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group label="Fin:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart" readonly></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeEnd"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right mr-2" @click="saveCal()">
            Sauvegarder
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.calendar.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="add_compte_fournisseur" title="Ajouter un fournisseur" ref="add_compte_fournisseur" hide-footer>
      <b-form>
        <b-form-group :label="$store.back.getText('type')+':'">
          <select class="form-control" v-model.number="compte_fournisseurTmp.prestataire_type">
            <option v-for="(t,it) in params.PrestataireType" :key="it" :value="t.id">{{t.titre.fr}}</option>
          </select>
        </b-form-group>
        <b-form-group :label="$store.back.getText('prestataire')+':'" v-if="compte_fournisseurTmp.prestataire_type != null">
          <treeselect :multiple="false" :options="params.Prestataire.filter(p => p.type_id == compte_fournisseurTmp.prestataire_type)" placeholder="Selection du prestataire" v-model="compte_fournisseurTmp.prestataire_id"/>          
        </b-form-group>
        <b-form-group :label="$store.back.getText('objet')+':'">
          <b-form-input v-model="compte_fournisseurTmp.objet" required></b-form-input>
        </b-form-group>
        <b-form-group :label="$store.back.getText('prix_achat')+':'">
          <b-form-input v-model.number="compte_fournisseurTmp.prix_achat" required></b-form-input>
        </b-form-group>
        <b-form-group :label="'TVA:'">
          <select class="form-control" v-model="compte_fournisseurTmp.tva">
              <option :value="18">Avec</option>
              <option :value="0">Sans</option>
          </select>          
        </b-form-group>
        
      </b-form>
      <b-row>
        <b-col md="12" class="text-center">
          <b-button variant="outline-primary" class="mr-3" @click="newPresta = true">
            Nouveau prestataire
          </b-button>
          <b-button variant="primary" @click="addCompteFournisseur()">
            Valide
          </b-button>
        </b-col>
      </b-row>  
      <div v-if="newPresta" class="mt-5">
        <span class="h4">Nouveau prestataire</span>        
        <b-row class="mt-3">
          <b-col md="6">
            <b-form-group label="Type">
              <select class="form-control" v-model.number="prestataire.type_id">
                <option v-for="(t,it) in params.PrestataireType" :key="it" :value="t.id">{{t.titre.fr}}</option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Societe:">
              <b-form-input v-model="prestataire.societe" required></b-form-input>
            </b-form-group>
          </b-col>          
          <b-col md="6">
            <b-form-group label="Numero de societe:">
              <b-form-input v-model="prestataire.numero_ste" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Libellé du compte:">
              <b-form-input v-model="prestataire.banque.libelle_compte" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Banque:">
              <b-form-input v-model="prestataire.banque.nom" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Agence:">
              <b-form-input v-model="prestataire.banque.agence" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Compte:">
              <b-form-input v-model="prestataire.banque.compte" required></b-form-input>
            </b-form-group>
          </b-col>

        </b-row>                        
        <b-row>
          <b-col md="12" class="text-center">          
            <b-button variant="primary" @click="addNewPresta()">
              Ajouter
            </b-button>
          </b-col>
        </b-row>  
      </div>
    </b-modal>
    <b-modal id="confirmation" title="Confirmation" ref="confirmation">
      <p>Vous êtes sur le point de supprimer une ligne !</p>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="danger" class="float-right" @click="removeRow()">
            Confirmer
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal ref="edit-tache" id="edit-tache" size="xl" title="Tache" hide-footer>            
      <div class="d-block" v-if="tache.doc.file_base64 != null">
        <b-row>
          <b-col md="8">
            <iframe :type="tache.doc.file_type" :src="`data:${tache.doc.file_type};base64,${tache.doc.file_base64}`" width="100%" height="800">                                 </iframe>
          </b-col>
          <b-col md="4">
            <b-form-group label="Type du document" class="text-center">
              <b-button-group>
                <b-button @click="tache.doc.ged_type = 9" :variant="tache.doc.ged_type === 9 ? 'success' : 'primary'">Acompte</b-button>
                <b-button class="ml-1" @click="tache.doc.ged_type = 10" :variant="tache.doc.ged_type === 10 ? 'success' : 'primary'">Facture</b-button>
              </b-button-group>              
            </b-form-group>
            <b-form-group label="Affilier le document a ?">
              <select class="form-control" v-model="tache.type">
                <option :value="null">Non</option>
                <option value="compte_fournisseur">Compte fournisseur</option>
                <option value="acompte_compte_fournisseur">Acompte compte fournisseur</option>
                <option value="compte_client">Compte client</option>
                <option value="acompte_compte_client">Acompte client</option>
              </select>
            </b-form-group>

            <b-form v-if="tache.type === 'compte_fournisseur'">
              <b-form-group :label="$store.back.getText('type')+':'">                
                <treeselect :multiple="false" :options="params.PrestataireType" placeholder="Selection du type" v-model="tache.compte_fournisseur.prestataire_type">                  </treeselect>
              </b-form-group>              
              <b-form-group label="Nouveau prestataire ?" class="text-center">
                <b-button-group>
                  <b-button @click="tache.new_presta = false" :variant="tache.new_presta === false ? 'success' : 'primary'">Non</b-button>
                  <b-button class="ml-1" @click="tache.new_presta = true" :variant="tache.new_presta === true ? 'success' : 'primary'">Oui</b-button>
                </b-button-group>              
              </b-form-group>
              <b-form-group :label="$store.back.getText('prestataire')+':'" v-if="tache.compte_fournisseur.prestataire_type != null && tache.new_presta === false">
                <treeselect :multiple="false" :options="params.Prestataire.filter(p => p.type_id == tache.compte_fournisseur.prestataire_type)" placeholder="Selection du prestataire" v-model="tache.compte_fournisseur.prestataire_id"/>
              </b-form-group>
              <b-form-group :label="$store.back.getText('prestataire')+':'" v-if="tache.compte_fournisseur.prestataire_type != null && tache.new_presta === true">
                <b-form-input v-model="tache.prestataire.societe" required></b-form-input>
              </b-form-group>
              <b-form-group :label="$store.back.getText('objet')+':'">
                <b-form-input v-model="tache.compte_fournisseur.objet" required></b-form-input>
              </b-form-group>
              <b-form-group :label="$store.back.getText('prix_achat')+' HT:'">
                <b-form-input v-model.number="tache.compte_fournisseur.prix_achat" required></b-form-input>
              </b-form-group>
              <b-form-group label="TVA" class="text-center">
                <b-button-group>
                  <b-button @click="tache.compte_fournisseur.tva = 0" :variant="tache.compte_fournisseur.tva === 0 ? 'success' : 'primary'">Sans (0%)</b-button>
                  <b-button class="ml-1" @click="tache.compte_fournisseur.tva = 18" :variant="tache.compte_fournisseur.tva === 18 ? 'success' : 'primary'">Avec (18%)</b-button>
                </b-button-group>              
              </b-form-group>              
            </b-form>
            <b-form v-if="tache.type === 'acompte_compte_fournisseur'">                          
              <b-form-group :label="$store.back.getText('prestataire')+':'">
                <treeselect :multiple="false" :options="params.Prestataire.filter(p => fournisseurs.includes(p.id))" placeholder="Selection du prestataire" v-model="tache.acompte_compte_fournisseur.prestataire_id"/>
              </b-form-group>              
              <b-form-group :label="$store.back.getText('type')+':'">                
                <treeselect :multiple="false" :options="params.AcompteType" placeholder="Selection du type" v-model="tache.acompte_compte_fournisseur.acompte_type">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.titre.fr }}</div>
                  <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName }" :class="labelClassName">{{ node.raw.titre.fr }}    </label>
                </treeselect>
              </b-form-group>   
              <b-form-group :label="$store.back.getText('montant')+':'">
                <b-form-input v-model.number="tache.acompte_compte_fournisseur.montant" required></b-form-input>
              </b-form-group>
              <b-form-group :label="$store.back.getText('date')+':'">
                <input type="date" class="form-control" v-model="tache.acompte_compte_fournisseur.date" style="display:overflow" />
              </b-form-group>
              <b-form-group :label="$store.back.getText('commentaire')+':'">
                <b-form-input v-model="tache.acompte_compte_fournisseur.commentaire" required></b-form-input>
              </b-form-group>    
              <b-form-checkbox size="lg" class="mt-2" v-model="tache.acompte_compte_fournisseur.facture_recu">Facture Reçu</b-form-checkbox>                        
            </b-form>
            <b-form v-if="tache.type === 'compte_client'">
              <b-form-group :label="$store.back.getText('type')+':'">                
                <treeselect :multiple="false" :options="params.PrestataireType" placeholder="Selection du type" v-model="tache.compte_client.prestataire_type">                  </treeselect>
              </b-form-group>              
              <b-form-group label="Nouveau prestataire ?" class="text-center">
                <b-button-group>
                  <b-button @click="tache.new_presta = false" :variant="tache.new_presta === false ? 'success' : 'primary'">Non</b-button>
                  <b-button class="ml-1" @click="tache.new_presta = true" :variant="tache.new_presta === true ? 'success' : 'primary'">Oui</b-button>
                </b-button-group>              
              </b-form-group>
              <b-form-group :label="$store.back.getText('prestataire')+':'" v-if="tache.compte_client.prestataire_type != null && tache.new_presta === false">
                <treeselect :multiple="false" :options="params.Prestataire.filter(p => p.type_id == tache.compte_client.prestataire_type)" placeholder="Selection du prestataire" v-model="tache.compte_client.prestataire_id"/>
              </b-form-group>
              <b-form-group :label="$store.back.getText('prestataire')+':'" v-if="tache.compte_client.prestataire_type != null && tache.new_presta === true">
                <b-form-input v-model="tache.prestataire.societe" required></b-form-input>
              </b-form-group>
              <b-form-group :label="$store.back.getText('objet')+':'">
                <b-form-input v-model="tache.compte_client.objet" required></b-form-input>
              </b-form-group>
              <b-form-group :label="$store.back.getText('prix_achat')+' HT:'">
                <b-form-input v-model.number="tache.compte_client.prix_achat" required></b-form-input>
              </b-form-group>
              <b-form-group :label="$store.back.getText('prix_vente')+' HT:'">
                <b-form-input v-model.number="tache.compte_client.prix_vente" required></b-form-input>
              </b-form-group>
              <b-form-group label="TVA" class="text-center">
                <b-button-group>
                  <b-button @click="tache.compte_client.tva = 0" :variant="tache.compte_client.tva === 0 ? 'success' : 'primary'">Sans (0%)</b-button>
                  <b-button class="ml-1" @click="tache.compte_client.tva = 18" :variant="tache.compte_client.tva === 18 ? 'success' : 'primary'">Avec (18%)</b-button>
                </b-button-group>              
              </b-form-group>              
            </b-form>
            <b-form v-if="tache.type === 'acompte_compte_client'">                                        
              <b-form-group :label="$store.back.getText('type')+':'">                
                <treeselect :multiple="false" :options="params.AcompteType" placeholder="Selection du type" v-model="tache.acompte_compte_client.acompte_type">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.titre.fr }}</div>
                  <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName }" :class="labelClassName">{{ node.raw.titre.fr }}    </label>
                </treeselect>
              </b-form-group>   
              <b-form-group :label="$store.back.getText('montant')+':'">
                <b-form-input v-model.number="tache.acompte_compte_client.montant" required></b-form-input>
              </b-form-group>
              <b-form-group :label="$store.back.getText('date')+':'">
                <input type="date" class="form-control" v-model="tache.acompte_compte_client.date" style="display:overflow" />
              </b-form-group>
              <b-form-group label="Num. Facture">
                <b-form-input v-model="tache.acompte_compte_client.facture_ref" required></b-form-input>
              </b-form-group>                  
            </b-form>
            <div class="text-center mt-3">
              <b-button variant="success" @click="saveTache()">
                <span>Sauvegarder</span>            
                <i v-if="load_tache" class="ml-2 fas fa-spin fa-spinner"></i>
              </b-button>
            </div>            
          </b-col>
        </b-row>                      
      </div>      
    </b-modal>
  </div>
</template>

<script>
function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Acompte from '@/component/propriete/Acompte'
export default {
  data(){
    return {
      ready:false,
      loader_save:false,
      confirmation:{
        type: null,
        uuid: null
      },
      loaderImportFile:false,
      docs_filter:null,
      doc_prestataire_id: null,
      toggleFiles:false,
      image:0,
      calDateStart:null,
      calTimeStart:"12:00",
      calTimeEnd:"12:30",
      calendarTmp:{
        name: null,
        start: null,
        end: null,
        detail: null,
        url:`/${location.hash}`
      },
      commentaireTmp:null,
      propriete:{
        "id": null,
        "adresse": {
          "administrative_area_level_1": null,
          "country": null,
          "latitude": null,
          "locality": null,
          "longitude": null,
          "route": null,
          "street_number": null,
          "quartier_id":null
        },
        "projet_id": null,
        "type_id": null,//aparaitre
        "status_id": null,
        "superficie": null,
        "etage": null,
        "commentaire": null,
        "orientation": null,
        "parking": null,
        "numero_propriete":null,
        "numero_place_parking":null,
        "nb_terrace":null,
        "superficie_terrace":null,
        "date_livraison":  null,
        "docs":[],
        "quotes": [],
        "titre":{
          "fr":"",
          "he":""
        },
        "description":{
          "fr":"",
          "he":""
        },
        "fournitures":[],
        "acomptes":[],
        "transaction_type_id":null,
        "prix_transaction":null,
        "location_type_id":[],
        "prix_achat":null,
        "style_id":null,
        "travaux_prix_vente":0,
        "vaad":null,
        "arnona":null,
        "date_create": null,
        "date_update": null,
        "status": 1,
      },
      projet: {
        "id": null,
        "titre": null,
        "date_livraison": {
          "mois": null,
          "annee": null
        },
        "constructeur": null,
        "type_id": null,
        "adresse": {
          "street_number": null,
          "route": null,
          "locality": null,
          "administrative_area_level_1": null,
          "country": null,
          "latitude": null,
          "longitude": null,
          "quartier_id":null
        },
        "commentaire": null,
        "logs":[],
        "docs":[],
        "status_id": 1,
        "status": 1,
        "date_create": null,
        "date_update": null
      },
      client:{
        "id": null,
        "nom": null,
        "prenom": null,
        "email": null,
        "telephone": null,
        "commentaire": "",
        "status_id": 1,
        "status": 1,
        "date_create": null,
        "date_update": null
      },
      tmpDoc:{
        "url": null,
        "ged_type": 1,
        "id":null,
        "prestataire_id": null,
        "date_create": null,
        "status": 1
      },
      params:{
        ProjetType:[],
        PrestataireType:[],
        ProprieteStyle:[],
        Prestataire:[],
        ProprieteStatus:[],
        GEDType:[],
        Langue:[],
        AcompteType:[],
        TransactionType:[],
        Quartier:[],
        LocationType:[]
      },
      compte_fournisseurTmp:{
        prestataire_type: null,
        prestataire_id: null,
        tva:18,
        uuid: null,
        objet: null,
        prix_achat: 0
      },
      newPresta: false,
      prestataire:{
        id: null,
        type_id:null,
        societe: null,
        numero_ste: null,
        banque: {
          libelle_compte:null,
          nom: null,
          agence: null,
          compte: null
        }
      },
      taches:[],    
      load_tache:false,  
      tache:{
        "propriete_id":null,
        "doc":{
          "tache_id":null,
          "ged_type":null,
          "file_name":null,
          "file_type":null,
          "file_base64":null
        },
        "type":null,
        "new_presta":false,        
        "compte_fournisseur":{
          "prestataire_type": null,
          "prestataire_id": null,
          "tva": 18,
          "uuid": null,
          "objet": null,
          "prix_achat": null,
          "index": null
        },
        "acompte_compte_fournisseur":{
          "prestataire_id": null,
          "uuid": null,
          "acompte_type": null,
          "montant": null,
          "date": null,
          "commentaire": null,
          "facture_recu": false
        },
        "compte_client": {
          "prestataire_type": null,
          "prestataire_id": null,
          "objet": null,
          "tva": 18,
          "uuid": null,
          "prix_achat": null,
          "prix_vente": null
        }, 
        "acompte_compte_client":{
          "prestataire_id": null,
          "uuid": null,
          "acompte_type": null,
          "montant": null,
          "date": null,
          "commentaire": null,
          "facture_ref": null
        }, 
        "prestataire":{
          "societe":null
        }
      }
    };
  },
  components: {
    VueGoogleAutocomplete,
    Treeselect,
    Acompte
  },
  watch: {
    calDateStart(){
      this.calendarTmp.start = this.calDateStart+' '+this.calTimeStart;
      this.calendarTmp.end = this.calDateStart+' '+this.calTimeEnd;
    },
    'propriete.superficie'(){
      if(this.propriete.superficie === null || this.projet.id === 0){
        return false;
      }
      this.propriete.arnona = this.propriete.superficie * this.projet.arnona_carre;
    },
  },
  methods: {
    save(alert = false){
      if(this.loader_save === true){
        return false;
      }
      if(this.propriete.status_id == 5 && alert === false){
        this.$refs.modalStatus.show();
        return true;
      }
      this.loader_save = true;
      this.$store.back.ajax('/propriete/update', this.propriete, (res) => {
        if(res.status){
          if(this.propriete.id === null){
            this.$router.push({path:'/propriete/detail/'+res.data.id});
            setTimeout(() => {location.reload()},500);
          }else{
            this.propriete = res.data;
            this.$bvToast.toast(`Propriete enregistre !`, {
              title: 'Sauvegarder !',
              variant: 'success',
              solid: true
            });
            this.$refs.modalStatus.hide();
          }
        }
        this.loader_save = false;
      });
    },
    saveCal(){
      this.$store.back.ajax('/calendrier/create', this.calendarTmp, (res) => {
        if(res.status === true){
          this.$bvToast.toast(`Votre rdv a ete sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          this.$refs.calendar.hide();
        }
      });
    },
    addCommentaire(){
      if(this.propriete.commentaire === null){
        this.propriete.commentaire = "";
      }
      var user = this.$store.back.getUser();
      var date = new Date();
      this.propriete.commentaire += `${this.commentaireTmp} | ${user.prenom} ${user.nom} ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`+"\n";
      this.commentaireTmp = null;
    },
    getAddressData(addressData) {
      addressData.quartier_id = null;
      this.propriete.adresse = addressData
    },
    addCompteClient(){
      this.propriete.compte_client.push({
        "prestataire_type": null,
        "prestataire_id": null,
        "objet": null,
        "tva":18,
        "uuid": uuidv4(),
        "prix_achat": 0,
        "prix_vente": 0
      });
    },
    addCompteFournisseur(){
      if(this.compte_fournisseurTmp.prestataire_id === null || this.compte_fournisseurTmp.prestataire_type === null){
        this.$bvToast.toast(`Merci de remplir tout les champs`, {
          title: 'Oupss !',
          variant: 'danger',
          solid: true
        });
        return false;
      }
      var tmp = JSON.parse(JSON.stringify(this.compte_fournisseurTmp));
      tmp.uuid = uuidv4()
      this.propriete.compte_fournisseur.push(tmp);
      this.compte_fournisseurTmp = {
        "prestataire_type": null,
        "prestataire_id": null,
        "objet": null,
        "tva":18,
        "prix_achat": 0,
      }
      this.$refs.add_compte_fournisseur.hide();
      return tmp.uuid()
    },
    addAcompteCompteFournisseur(prestataire_id = null){
      this.propriete.acompte_compte_fournisseur.push({
        "prestataire_id": prestataire_id,
        "uuid": uuidv4(),
        "acompte_type": null,
        "montant": 0,
        "date": null,
        "commentaire":null
      });
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    },
    addCompteDirect(){
      this.propriete.compte_direct.push({
        "prestataire_type": null,
        "prestataire_id": null,
        "objet": null,
        "tva":18,
        "uuid": uuidv4(),
        "prix_achat": 0,
        "commission": 0
      });
    },
    changeStatus(id){
      if(this.propriete.status_id === id){
        return false;
      }
      var user = this.$store.back.getUser();
      var now = this.$store.back.now();
      this.propriete.logs.push({
        user_id:user.id,
        module:"lead",
        old: this.propriete.status_id,
        new: id,
        body: `La propriete a change de statut de '${this.params.ProprieteStatus.find(l => l.id === this.propriete.status_id).titre.fr}' a '${this.params.ProprieteStatus.find(l => l.id === id).titre.fr}' par ${user.prenom} ${user.nom} le ${this.$store.back.timestampToDate(now)}`,
        date_create:now
      });
      this.propriete.status_id = id;
    },
    getFileName(obj){
      var q = obj.url.slice(obj.url.indexOf("?")+1);
      var params = new URLSearchParams(q);
      var filename = params.get('q');
      //filename = filename.slice(0,19-3)
      return filename;
    },
    removeFile(id){
      this.propriete.docs = this.propriete.docs.filter(d => d.id != id)
    },
    handleFile(e){
      var the = this;
      this.loaderImportFile = true;
      var files = e.target.files;
      files.forEach((item) => {
        the.upladFile(item);
      });
    },
    upladFile(file){
      const fileUploaded = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        var tmp = {
          'file': e.target.result,
          'file_name': fileUploaded.name
        }
        this.$store.back.ajax('/ged/import', tmp, (res) => {
          if(res.status == true){
            this.tmpDoc.url = res.data;
            this.tmpDoc.date_create = Math.floor(Date.now() / 1000);
            this.loaderImportFile = false;
          }
        })
      }
      reader.readAsDataURL(fileUploaded);
    },
    addDoc(){
      if(this.tmpDoc.url === null){
        this.$bvToast.toast(`Merci de remplir tout les champs`, {
          title: 'Oupss !',
          variant: 'danger',
          solid: true
        });
        return false;
      }
      var tmp = JSON.stringify(this.tmpDoc);
      tmp = JSON.parse(tmp);
      tmp.id = Date.now()
      this.propriete.docs.push(tmp);
      this.tmpDoc.url = null;
      this.tmpDoc.ged_type = 1;
      this.tmpDoc.date_create = null;
      this.tmpDoc.prestataire_id = null;
      this.$refs.import.hide();
      this.save();
    },
    formatedNumber(val){
      val = parseFloat(val);
      val = val.toFixed(2)
      val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return val;
    },
    addDocRow(uuid){
      this.tmpDoc.prestataire_id = uuid;
      this.$refs.import.show();
    },
    openDocRow(uuid){
      this.doc_prestataire_id = uuid;
      this.toggleFiles = true;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    docRowEmpty(uuid){
      var tmp = this.propriete.docs.filter(d => d.prestataire_id === uuid);
      return tmp.length === 0 ? true : false;
    },
    openConfirmation(type,uuid){
      this.confirmation.type = type;
      this.confirmation.uuid = uuid;
      this.$refs.confirmation.show();
    },
    removeRow(){
      if(this.confirmation.type === null || this.confirmation.uuid === null){
        return false;
      }
      this.propriete[this.confirmation.type] = this.propriete[this.confirmation.type].filter(e => e.uuid != this.confirmation.uuid)
      this.confirmation = {
        type: null,
        uuid:  null
      };
      this.$refs.confirmation.hide();
    },
    recapPDF(){
      var url = this.$store.back.urlAPI+'/propriete/pdf/'+this.propriete.id;
      fetch(url, {
        method: 'GET',
        headers: new Headers({
          "Authorization": this.$store.back.getToken()
        })
      })
      .then(response => response.blob())
      .then(blob => {
        console.log(blob);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'chantier #000'+this.propriete.id+'.pdf';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again
      });
    },
    recapFournisseur(prestataire_id){
      var url = `${this.$store.back.urlAPI}/propriete/fournisseur?propriete_id=${this.propriete.id}&prestataire_id=${prestataire_id}`;
      fetch(url, {
        method: 'GET',
        headers: new Headers({
          "Authorization": this.$store.back.getToken()
        })
      })
      .then(response => response.blob())
      .then(blob => {
        console.log(blob);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'chantier #000'+this.propriete.id+'.pdf';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again
      });
    },
    deletePropriete(){
      this.propriete.status = 0;
      this.save();
      setTimeout(() => {
        this.$router.push({path:'/propriete/liste'})
      },1000)
    },
    sortAcompteFournisseur(){
      this.propriete.acompte_compte_fournisseur = this.propriete.acompte_compte_fournisseur.sort((a,b) => {
        var date1 = new Date(a.date);
        var date2 = new Date(b.date);
        return (a.prestataire_id - b.prestataire_id) || (date1 - date2);
      }); 
    },
    addNewPresta(){
      this.$store.back.ajax('/prestataire/create', this.prestataire, res => {
        if(res.status === true){
          res.data.label = res.data.societe;
          this.params.Prestataire.push(res.data);
          this.compte_fournisseurTmp.prestataire_type = res.data.type_id;
          this.compte_fournisseurTmp.prestataire_id = res.data.id;
          this.newPresta = false;
          this.prestataire = {
            id: null,
            type_id: null,
            societe: null,
            numero_ste: null,
            banque: {
              libelle_compte:null,
              nom: null,
              agence: null,
              compte: null
            }
          }
        }
      })
    },
    openTache(t){
      var tmp = JSON.parse(JSON.stringify(t));
      this.tache.doc.file_type = tmp.ext;
      this.tache.doc.file_base64 = tmp.base64;
      this.tache.doc.tache_id = tmp.id;
      this.tache.propriete_id = this.propriete.id;      
      this.$refs['edit-tache'].show()
    },
    saveTache(){
      if(this.load_tache === true){
        return false;
      }
      this.load_tache = true;
      this.$store.back.ajax('/propriete/tache', this.tache, res => {
        if(res.status === true){
          this.propriete = res.data.propriete;
          this.projet = res.data.projet;
          this.client = res.data.client;
          this.taches = res.data.taches;
          this.tache = {
            "propriete_id":null,
            "doc":{
              "tache_id":null,
              "ged_type":null,
              "file_name":null,
              "file_type":null,
              "file_base64":null
            },
            "type":null,
            "new_presta":false,        
            "compte_fournisseur":{
              "prestataire_type": null,
              "prestataire_id": null,
              "tva": 18,
              "uuid": null,
              "objet": null,
              "prix_achat": null,
              "index": null
            },
            "acompte_compte_fournisseur":{
              "prestataire_id": null,
              "uuid": null,
              "acompte_type": null,
              "montant": null,
              "date": null,
              "commentaire": null,
              "facture_recu": false
            },
            "compte_client": {
              "prestataire_type": null,
              "prestataire_id": null,
              "objet": null,
              "tva": 18,
              "uuid": null,
              "prix_achat": null,
              "prix_vente": null
            }, 
            "acompte_compte_client":{
              "prestataire_id": null,
              "uuid": null,
              "acompte_type": null,
              "montant": null,
              "date": null,
              "commentaire": null,
              "facture_ref": null
            }, 
            "prestataire":{
              "societe":null
            }
          }
          this.$refs['edit-tache'].hide();
        }
        this.load_tache = false;
      })
    },
    deleteTache(id){
      this.$store.back.ajax('/propriete/tache/delete/'+id, null, res => {
        if(res.status){
          this.taches = this.taches.filter(t => t.id != id);
        }
      })
    }
  },
  computed: {
    totalVente(){
      var total = 0;
      this.propriete.compte_client.forEach((item) => {
        total += parseFloat(item.prix_vente);
      });
      return total
    },
    totalVenteTVA(){
      var total = 0;
      this.propriete.compte_client.forEach((item) => {
        total += parseFloat(item.prix_vente)*(item.tva/100);
      });
      return total
    },
    totalAchat(){
      var total = 0;
      this.propriete.compte_client.forEach((item) => {
        total += parseFloat(item.prix_achat)
      });
      return total
    },
    totalAchatTVA(){
      var total = 0;
      this.propriete.compte_client.forEach((item) => {
        total += parseFloat(item.prix_achat)*(item.tva/100)
      });
      return total
    },
    totalAcompte(){
      var total = 0;
      this.propriete.acompte_compte_client.forEach((item) => {
        total += parseFloat(item.montant);
      });
      return total;
    },
    totalAcompteFournisseur(){
      var total = 0;
      this.propriete.acompte_compte_fournisseur.forEach((item) => {
        total += parseFloat(item.montant);
      });
      return total;
    },
    beneficeFournisseur(){
      var total = 0;
      this.propriete.compte_direct.forEach((item) => {
        total += item.commission
      });
      return total;
    },
    proprieteStatus(){
      var check = this.params.ProprieteStatus.find(s => s.id == this.propriete.status_id);
      if(check === undefined){
        check = {
          id:1,
          titre:{
            fr:"Disponible"
          },
          color:"primary"
        }
      }
      return check;
    },
    docsFiltered(){
      var res = [];
      if(this.doc_prestataire_id === null){
        if(this.docs_filter === null){
          res = this.propriete.docs;
        }else{
          this.propriete.docs.forEach((item, i) => {
            if(item.ged_type === this.docs_filter){
              item.index = i;
              res.push(item);
            }
          });
        }
      }else{
        this.propriete.docs.forEach((item, i) => {
          if(item.prestataire_id === this.doc_prestataire_id){
            item.index = i;
            res.push(item);
          }
        });
      }
      return res;
    },
    compteFournisseur(){
      var res = [];
      this.propriete.compte_fournisseur.forEach((item, i) => {
        item.index = i;
        var check = res.find(r => r.prestataire_id === item.prestataire_id);
        if(check === undefined){
          res.push({
            prestataire_type: item.prestataire_type,
            prestataire_id: item.prestataire_id,
            objet: item.objet,
            total_achat: item.prix_achat,
            total_tva: item.prix_achat * (item.tva/100),
            total_acompte:0,
            detail:[item],
            view:false
          })
        }else{
          check.total_achat += parseFloat(item.prix_achat);
          check.total_tva += parseFloat(item.prix_achat) * (item.tva/100);
          check.detail.push(item)
        }
      });
      res.forEach((item) => {
        var acomptes = this.propriete.acompte_compte_fournisseur.filter(a => a.prestataire_id === item.prestataire_id);
        if(acomptes.length > 0){
          item.total_acompte = 0;
          acomptes.forEach((acompte) => {
            item.total_acompte += parseFloat(acompte.montant);
          });
        }
      });
      return res;
    },
    fournisseurs(){
      var res = [];
      this.propriete.compte_fournisseur.forEach(p => {
        if(!res.includes(p.prestataire_id)){
          res.push(p.prestataire_id);          
        }
      })
      return res;
    },    
    totalFournisseurTTC(){
      var total = 0;
      this.propriete.compte_fournisseur.forEach((item) => {
        total += item.prix_achat + (parseFloat(item.prix_achat)*(item.tva/100))
      });
      return total
    },
  },
  beforeMount(){
    if(this.$route.params.id != undefined){
      this.$store.back.ajax(`/propriete/${this.$route.params.id}`, null, (res) => {
        if(res.status){
          this.propriete = res.data.propriete;
          this.projet = res.data.projet;
          this.client = res.data.client;
          this.taches = res.data.taches;
          this.sortAcompteFournisseur();
        }
      });
    }
    let params = {
      modules: ["ProjetType","PrestataireType","Prestataire","ProprieteStatus","GEDType","Langue","AcompteType","ProprieteStyle","TransactionType","LocationType","Quartier"]
    };
    this.$store.back.ajax('/magic/all', params, (res) => {
      if(res.status === true){
        this.params.status = res.data['status'];
        res.data.ProjetType.forEach((item) => {
          item.label = item.titre.fr
        });
        this.params.ProjetType = res.data['ProjetType'];
        res.data['PrestataireType'].forEach((item) => {
          item.label = item.titre.fr;
        })
        this.params.PrestataireType = res.data['PrestataireType'];
        res.data.Prestataire.forEach((item) => {
          item.label = item.societe
        });
        this.params.Prestataire = res.data['Prestataire'];
        this.params.ProprieteStatus = res.data['ProprieteStatus'];
        this.params.ProprieteStyle = res.data['ProprieteStyle'];
        this.params.Langue = res.data['Langue'];
        this.params.AcompteType = res.data['AcompteType'];
        this.params.GEDType = res.data['GEDType'];
        this.params.TransactionType = res.data['TransactionType'];
        this.params.Quartier = res.data['Quartier'];
        res.data.LocationType.forEach((item) => {
          item.label = item.titre.fr
        });
        this.params.LocationType = res.data['LocationType'];
        this.ready = true;
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);    
  }
};
</script>
<style media="screen">
.collapse.show{
  display: contents !important;
}
</style>
